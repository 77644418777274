

<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="45" height="45">
        <defs>
                <clipPath  id="1">

                    <path clip-rule="evenodd" d="M143 131L307 131L307 319L143 319z" />        </clipPath>
        </defs>

        <g transform="matrix(0.10000001 0 0 0.10000001 0 0)">
            <path d="M437.5 225C 437.50003 283.68027 416.75342 333.767 375.2602 375.2602C 333.767 416.75342 283.68027 437.50003 225 437.5C 166.31975 437.50003 116.23302 416.75342 74.73981 375.2602C 33.246605 333.767 12.500001 283.68027 12.5 225C 12.500001 166.31975 33.246605 116.23302 74.73981 74.73981C 116.23302 33.246605 166.31975 12.500001 225 12.5C 283.68027 12.500001 333.767 33.246605 375.2602 74.73981C 416.75342 116.23302 437.50003 166.31975 437.5 225C 437.50003 227.47281 437.45688 229.94485 437.37054 232.41614" :stroke="secondaryColor" :style="{opacity: opacity}" stroke-width="25" fill="none" />
            <g clip-path="url(#1)" >
                <path d="M298.961 131L249.775 131C 245.335 131 241.736 134.607 241.736 139.057L241.736 139.057L241.736 310.943C 241.736 315.393 245.335 319 249.775 319L249.775 319L298.961 319C 303.401 319 307 315.393 307 310.943L307 310.943L307 139.057C 307 134.607 303.401 131 298.961 131z" stroke="none" :fill="color" fill-rule="nonzero" />
                <path d="M200.225 131L151.039 131C 146.599 131 143 134.607 143 139.057L143 139.057L143 310.943C 143 315.393 146.599 319 151.039 319L151.039 319L200.225 319C 204.665 319 208.264 315.393 208.264 310.943L208.264 310.943L208.264 139.057C 208.264 134.607 204.665 131 200.225 131z" stroke="none" :fill="color" fill-rule="nonzero" />
            </g>
        </g>
        </svg>
  </template>
  
  <script>
  export default {
    name: 'PauseOutline',
    props: {
      color: {
        type: String,
        required: true,
      },
      secondaryColor: {
        type: String,
        required: true,
      },
      opacity: {
        type: Number,
        default: 1
      },
    },
  }
  </script>
  
  <style lang="css" scoped>
  </style>